
import Http from './Http'

export default {
    async getCustomerStatistics(days) {
        return await Http.get(`/statistics/users?days=${days}`)
    },
    async getDevicesStatistics(days) {
        return await Http.get(`/statistics/devices/active?days=${days}`)
    },
    async getOrdersTotalStatistics(days) {
        return await Http.get(`/statistics/orders?days=${days}`)
    },
    async getOrdersStatisticsByType(days, graphType) {
        return await Http.get(`/statistics/ordersByType?days=${days}&type=${graphType}`)
    },
    async getTicketingTotalStatistics(days) {
        return await Http.get(`/statistics/ticketing?days=${days}`)
    },
    async getCustomerDevicesStatistics(days,graphType) {
        return await Http.get(`/statistics/customerDevices?days=${days}&type=${graphType}`)
    },
    async getSessionsStatistics(days) {
        return await Http.get(`/statistics/sessions/active?days=${days}`)
    },

}


